
import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Ref} from 'vue-property-decorator';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {services} from '@/main';
import Password from '@/components/personnal-info/PwdDef.vue';
import MailService from '@/components/user-services/MailService.vue';
import BalladeService from '@/components/user-services/BalladeService.vue';
import VisioSoftService from '@/components/user-services/VisioSoftService.vue';
import {IMailService, IUserService} from '@/services/dto/IUserService';
import AlertService from '@/utils/AlertService';
import UserCard from '@/components/UserCard.vue';
import InfoHeadMenu from '@/components/personnal-info/InfoHeadMenu.vue';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import Tab1UserIdentity from '@/components/personnal-info/Tab1UserIdentity.vue';
import Tab1UserPassword from '@/components/personnal-info/Tab1UserPassword.vue';
import {EnumUserServiceType, IAccountIdentity} from '@/services/dto/IDtos';
import '@/css/personnal-info/menu-personal-info.css';

@Component({
  components: {
    Tab1UserPassword,
    Tab1UserIdentity,
    SigInputTexte,
    InfoHeadMenu,
    Password,
    MailService,
    BalladeService,
    VisioSoftService,
    UserCard,
  },
})
export default class PersonalInformartion extends AbstractComponentVue {
  private tabIndex: number = 0;
  private deactiveAllOtherTab: boolean = false;

  // pour EditPersonnal
  private iAccountIdentity: IAccountIdentity = {} as IAccountIdentity;

  // Attribut utilisé pour faire un bind sur la prop refresh de UserCard
  private onePasswordIsUpdated: boolean = false;

  // Variables utiles pour le fonctionnement des tabs
  /** Classs CSS d'activation des tabs */
  private showTabMail: boolean = false;
  private showTabBallade: boolean = false;
  private showTabVisio: boolean = false;

  private activeTabType: EnumUserServiceType = EnumUserServiceType.MER;

  // Les services de l'utilisateur

  // Ref contenu des onglets
  @Ref('refMerPwd') private refMerPwd?: Tab1UserPassword;
  @Ref('refMailService') private refMailService?: MailService;
  @Ref('refBalladeService') private refBalladeService?: BalladeService;
  @Ref('refVisioSoftService') private refVisioSoftService?: VisioSoftService;

  // Faire en sorte de soliciter le store systématiquement pour les attributs utilisateur
  public get fullUser(): IUserResponse {
    return this.$store.getters.fullUser;
  }

  /**
   * Méthode appelée quand l'event passwordUpdated est reçu
   */
  private receivePasswordUpdated(): void {
    // On alterne la valeur car elle est seulement utilisée pour le bind de la prop refresh de UserCard
    this.onePasswordIsUpdated = !this.onePasswordIsUpdated;
  }

  public get balladeService(): IUserService {
    return this.$store.getters.balladeService;
  }

  public get visioService(): IUserService {
    return this.$store.getters.visioService;
  }

  public get mailService(): IMailService {
    return this.$store.getters.fullUser.mailServiceExterne ? {} as IMailService
        : this.$store.getters.mailService;
  }

  /**
   * Class de l'entête du tab Perso.
   */
  private persoTabClass(): string {
    if (this.activeTabType === EnumUserServiceType.MER) {
      return 'customActive';
    } else {
      return '';
    }
  }

  /**
   * Class de l'entête du tab à partir du statue du service utilisateur
   */
  private classForTabByUserService(userService: IUserService): string {
    // Si l'onglet du userService est actuelement affiché
    const isActive: boolean = userService.serviceType === this.activeTabType;
    if (isActive && userService.state === 'BEING_VALIDATED') {
      return 'customActiveRed';
    } else if (isActive) {
      return 'customActive';
    } else {
      return '';
    }
  }

  private goPerso() {
    this.erasePasswordFromOriginePage();
    this.activeTabType = EnumUserServiceType.MER;
    const target: string = window.location.pathname;
    if (target !== '/account/personal-info/personnal-info') {
      services.routageService.goTo('/account/personal-info/personnal-info');
    }
  }

  /**
   * Notification de l'absence de mail de secours.
   */
  private notifyMissingRescueMail() {
    this.goPerso();
    this.deactiveAllOtherTab = true;
    AlertService.warning(this.$store.getters.msgMailRescueWarn);
    setInterval(() => {
      this.deactiveAllOtherTab = false;
    }, 100);
  }

  private goMail() {
    if (this.$store.getters.fullUser.rescueEmail !== null && this.$store.getters.fullUser.rescueEmail !== '') {
      this.erasePasswordFromOriginePage();
      this.activeTabType = EnumUserServiceType.MAIL;
      const target: string = window.location.pathname;
      if (target !== '/account/personal-info/MAIL') {
        services.routageService.goTo('/account/personal-info/MAIL');
      }
    } else {
      this.notifyMissingRescueMail();
    }
  }

  private goBallade() {
    if (this.$store.getters.fullUser.rescueEmail !== null && this.$store.getters.fullUser.rescueEmail !== '') {
      this.erasePasswordFromOriginePage();
      this.activeTabType = EnumUserServiceType.BALLADE;
      const target: string = window.location.pathname;
      if (target !== '/account/personal-info/BALLADE') {
        services.routageService.goTo('/account/personal-info/BALLADE');
      }
    } else {
      this.notifyMissingRescueMail();
    }
  }

  /**
   * Efface le contenu du mdp saisie lors d'un changement d'onglet.
   */
  private erasePasswordFromOriginePage(): void {
    if (this.activeTabType === EnumUserServiceType.MER && this.refMerPwd !== undefined) {
      this.refMerPwd.erasePassword();
    } else if (this.activeTabType === EnumUserServiceType.MAIL && this.refMailService !== undefined) {
      this.refMailService.erasePassword();
    } else if (this.activeTabType === EnumUserServiceType.BALLADE && this.refBalladeService !== undefined) {
      this.refBalladeService.erasePassword();
    }
    if (this.activeTabType === EnumUserServiceType.VISIO_SOFT && this.refVisioSoftService !== undefined) {
      this.refVisioSoftService.erasePassword();
    }
  }

  private goVisio() {
    if (this.$store.getters.fullUser.rescueEmail !== null && this.$store.getters.fullUser.rescueEmail !== '') {
      this.erasePasswordFromOriginePage();
      this.activeTabType = EnumUserServiceType.VISIO_SOFT;
      const target: string = window.location.pathname;
      if (target !== '/account/personal-info/VISIO_SOFT') {
        services.routageService.goTo('/account/personal-info/VISIO_SOFT');
      }
    } else {
      this.notifyMissingRescueMail();
    }
  }

  private created() {
    // Rechargement des informations du store au chargement de la page
    this.reloadFromStore();

    // Boucle sur les userServices
    if ( this.mailService.serviceType === EnumUserServiceType.MAIL
        && this.mailService.state !== 'DELETED'
        && !this.mailService.validationRequested
        && !this.fullUser.mailServiceExterne) {
      // On affiche le tab MAIL
      this.showTabMail = true;
    }
    if (this.balladeService.serviceType === EnumUserServiceType.BALLADE && this.balladeService.state !== 'DELETED') {
      // On affiche le tab BALLADE
      this.showTabBallade = true;
    }
    if (this.visioService.serviceType === EnumUserServiceType.VISIO_SOFT && this.visioService.state !== 'DELETED') {
      // On affiche le tab VISIO_SOFT
      this.showTabVisio = true;
    }

    // Activation des tabs
    this.automatiqueActivationTab();

    // La modification d'identité
    this.iAccountIdentity.firstName = this.fullUser.firstName as string;
    this.iAccountIdentity.lastName = this.fullUser.lastName as string;
    this.iAccountIdentity.entityName = this.fullUser.entityName as string;
    this.iAccountIdentity.accountId = this.fullUser.accountId;
    this.iAccountIdentity.admin = this.fullUser.admin as boolean;
    this.iAccountIdentity.roleType = this.fullUser.authorities ? this.fullUser.authorities[0] : '';
  }

  /**
   * Fonction de rafraichissement des attributs de la classe depuis le store
   */
  private reloadFromStore() {
    services.loginService.ifCurrentUserReloadStore(this.fullUser.accountId);
  }

  /**
   * Gestion de l'activation des TAB selon l'existence du type service utilisateur et l'URL.
   */
  private automatiqueActivationTab(): void {
    const target: string = window.location.pathname;
    if (target.includes('MAIL')) {
      if (this.showTabMail) {
        this.goMail();
      } else {
        this.goPerso();
      }
    } else if (target.includes('BALLADE')) {
      if (this.showTabBallade) {
        this.goBallade();
      } else {
        this.goPerso();
      }
    } else if (target.includes('VISIO')) {
      if (this.showTabVisio) {
        this.goVisio();
      } else {
        this.goPerso();
      }
    } else {
      this.goPerso();
    }
  }

}
