
import AbstractComponentVue from '../../services/AbstractComponentVue';
import {Component, Ref} from 'vue-property-decorator';
import Password from '@/components/personnal-info/PwdDef.vue';
import {IUserService} from '@/services/dto/IUserService';
import {services, store} from '@/main';
import {IPasswordRequest} from '@/services/dto/IDtos';
import AlertService from '@/utils/AlertService';
import UserServicesUtils from '@/services/UserServicesUtils';

const userServicesUtils = new UserServicesUtils();

@Component({
	components: {
		Password,
	},
})
export default class BalladeService extends AbstractComponentVue  {
  @Ref('balladePwd') private passwordGen?: Password;
	private balladeService?: IUserService = store.getters.balladeService;
  private passwordValidity: boolean = false;

  private balladeArtTypeLabel: string = store.getters.fullUser.balladeArtTypeLabel;
  private balladeInstallGuideKey: any = userServicesUtils.getBalladeInstallGuideKey();
  private balladeSoftwareLink: any = store.getters.balladeInstallerLink;
  private vpnAdnovSoftwareLink: string = store.getters.vpnAdnovSoftwareLink;
  private merGuideInstallVpnAdnovKey: string = 'MER_GUIDE_INSTALLATION_BALLADEWAN';
  private merGuideInstallBalladeKey: string = 'MER_GUIDE_INSTALLATION_TOUS_SUPPORT';
  private merFicheVpnAdnovKey: string = 'MER_FICHE_VPN_ADNOV';
  private merFicheBalladekey: string = 'MER_FICHE_BALLADE';

  /**
   * Vide les champs MDP Ballade.
   */
  public erasePassword(): void {
    if (this.passwordGen !== undefined) {
      this.passwordGen.erasePassword();
    }
  }

  private watchPasswordValid(valid: boolean) {
    this.passwordValidity =  valid;
  }

	/**
	 * Enregistre la modification du mot de passe Ballade.
	 */
  private saveBalladePassword(): void {
    const passwordRequest: IPasswordRequest = {} as IPasswordRequest;
    passwordRequest.oldPassword = '';
    passwordRequest.newPassword = this.passwordGen !== undefined ? this.passwordGen.newPassword : '';
    passwordRequest.userServiceType = 'BALLADE';
    // Le fenêtre pantientez.
    this.showModal('modal-traitement-cours');
    services.restService.callPatch('/api/v1/connected-user/password', passwordRequest)
        .then((res) => {
          if (res.status === 200) {
            // On recharge les info fraîchement modifiées dans le localStorage
            services.loginService.ifCurrentUserReloadStore(store.getters.connectedUserAccountId,
                () => {
                  this.hideModal('modal-traitement-cours');
                  // Lance l'évènement passwordUpdated quand le mot de passe est mis à jour
                  this.$emit('passwordUpdated');
                  let label = this.balladeArtTypeLabel === 'BALLADEWAN' ? 'VPN ADNOV' : 'ballade';
                  AlertService.success(
                      'Vous pouvez dès à présent paramétrer votre accès ' + label + ' sur votre poste de travail.');
                });
            // On efface les input MDP.
            if (this.passwordGen !== undefined) {
                this.passwordGen.erasePassword();
                this.watchPasswordValid(false);
            }
          } else {
            this.hideModal('modal-traitement-cours');
          }
        })
        .catch((respError) => {
          this.hideModal('modal-traitement-cours');
        });
  }
}
