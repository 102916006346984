
import {Component, Ref} from 'vue-property-decorator';
import AbstractComponentVue from '../../services/AbstractComponentVue';
import Password from '@/components/personnal-info/PwdDef.vue';
import {services, store} from '@/main';
import {IUserService} from '@/services/dto/IUserService';
import {IPasswordRequest} from '@/services/dto/IDtos';
import AlertService from '@/utils/AlertService';

@Component({
	components: {
		Password,
	},
})
export default class MailService extends AbstractComponentVue  {

  @Ref('mailPwd') private passwordGen?: Password;
  private accountId: number = store.getters.fullUser.accountId;
  private passwordValidity: boolean = false;
  private mailService?: IUserService = store.getters.mailService;
  private urlTransfertMail: string = services.config.config.$api_url + '/api/v1/user-services/mail/'
      + this.accountId + '/get-mail-transfert-form-document/';


  /**
   * Vide les champs MDP Mail.
   */
  public erasePassword(): void {
    if (this.passwordGen !== undefined) {
      this.passwordGen.erasePassword();
    }
  }

  private watchPasswordValid(valid: boolean) {
    this.passwordValidity = valid;
  }

  /**
   * Enregistrement effectif du nouveau mot de passe MAIL
   */
  private saveMailPassword() {
    const passwordRequest: IPasswordRequest = {} as IPasswordRequest;
    passwordRequest.oldPassword = '';
    passwordRequest.newPassword = this.passwordGen !== undefined ? this.passwordGen.newPassword : '';
    passwordRequest.userServiceType = 'MAIL';

    // Le fenêtre pantientez.
    this.showModal('modal-traitement-cours');
    services.restService.callPatch('/api/v1/connected-user/password', passwordRequest)
        .then((res) => {
          if (res.status === 200) {
            // On recharge les info fraîchement modifiées dans le localStorage
            services.loginService.ifCurrentUserReloadStore(store.getters.connectedUserAccountId,
                () => {
                  this.hideModal('modal-traitement-cours');
                  // Lance l'évènement passwordUpdated quand le mot de passe est mis à jour
                  this.$emit('passwordUpdated');
                  AlertService.success('La modification de votre mot de passe bien été prise en compte.');
                });
            // On efface les input MDP.
            if (this.passwordGen !== undefined) {
              this.passwordGen.erasePassword();
              this.watchPasswordValid(false);
            }
          }
        })
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }
}
