
import {Component, Prop, Vue} from 'vue-property-decorator';
import {IUserService} from '@/services/dto/IUserService';
import {EnumUserServiceType} from '@/services/dto/IDtos';

@Component({})
export default class InfoHeadMenu extends Vue {
  @Prop() private text!: string;
  @Prop() private icon!: string;
  @Prop() private tooltipServiceNotComplete!: string;
  @Prop() private usService?: IUserService;
  @Prop() private beingValid?: boolean;
  @Prop() private passwordMailExpired?: boolean;
  @Prop() private activeTabActive?: boolean;
  @Prop() private icnType?: string;

  public showAdsn(): boolean {
    return this.icnType !== undefined && this.icnType.toUpperCase() === 'ADSN';
  }

  public showAdnov(): boolean {
    return this.icnType !== undefined && this.icnType.toUpperCase() === 'ADNOV';
  }

  get isActive() {
    return this.activeTabActive !== undefined && this.activeTabActive;
  }

  get isValidationEncours() {
    return this.usService !== undefined && this.usService.state === 'BEING_VALIDATED';
  }

  get isPassworMailExpired() {
    return this.passwordMailExpired !== undefined && this.passwordMailExpired;
  }

  get tooltipContent(): string {
    if (this.isValidationEncours) {
      let label: string = '';
      if (this.usService?.serviceType === EnumUserServiceType.BALLADE) {
        label = 'Ballade';
      } else if (this.usService?.serviceType === EnumUserServiceType.MAIL) {
        label = 'Messagerie';
      } else if (this.usService?.serviceType === EnumUserServiceType.VISIO_SOFT) {
        label = 'Visioconférence';
      }
      return 'Les données ' + label + ' sont incomplètes';
    } else if (this.isPassworMailExpired) {
      return 'Le mot de passe de votre messagerie a plus de 6 mois';
    }
    return '';
  }

  /**
   * Class de FontAwesome mail.
   */
  private get faMailClass(): string {
    if (this.isActive) {
      return 'text-light';
    } else if (this.isPassworMailExpired) {
      return 'text-warning';
    } else if (this.isValidationEncours) {
      return 'inf-perso-color-red';
    } else {
      return 'text-danger';
    }
  }
}
