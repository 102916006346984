
import {Component, Ref} from 'vue-property-decorator';
import {services} from '@/main';
import {IPasswordRequest} from '@/services/dto/IDtos';
import AlertService from '@/utils/AlertService';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import Password from '@/components/personnal-info/PwdDef.vue';

@Component({
  components: {SigInputTexte, Password},
})
export default class Tab1UserPassword extends AbstractComponentVue {
  private formPwdValid: boolean = false;
  @Ref('pwdMer') private passwordGen!: Password;
  @Ref('refCurrentPassword') private inputCurrentPassword!: SigInputTexte;
  private passwordValidity: boolean = false;
  // Modification du MDP MER.
  private currentPassword: ISigInputTextDto =
      {value: '', hasError: true, msgError: ''};

  /**
   * Vide les champs MDP MER.
   */
  public erasePassword(): void {
    if (this.passwordGen !== undefined) {
      this.passwordGen.erasePassword();
    }
    if (this.currentPassword !== undefined) {
	    this.currentPassword.value = '';
    	this.currentPassword.hasError = true;
    	this.currentPassword.msgError = '';
    }
  }

  private watchPasswordValid(valid: boolean) {
    this.passwordValidity = valid;
  }

  private saveMerPassword(): void {
    // Enregistrement du changement de mot de passe.
    if (this.formPwdValid) {
      const passwordRequest: IPasswordRequest = {} as IPasswordRequest;
      passwordRequest.oldPassword = this.currentPassword.value;
      passwordRequest.newPassword = this.passwordGen !== undefined ? this.passwordGen.newPassword : '';
      passwordRequest.userServiceType = 'MER';
      // Le fenêtre pantientez.
      this.showModal('modal-traitement-cours');
      services.restService.callPatch('/api/v1/connected-user/password', passwordRequest)
          .then((res) => {
                if (res.status === 200) {
                  AlertService.success('La modification de votre mot de passe Mon Espace Real a bien été prise en compte.');
                  // On efface les input MDP.
                  this.passwordGen.erasePassword();
                }
              },
          )
          .catch((res) => {
            console.log('Plantage de l\'appel de la modification du mdp');
            if (res.data !== undefined && res.data.description !== undefined && res.data.description !== null) {
              this.inputCurrentPassword.error(res.data.description);
            } else if (res.data !== undefined && res.data.message !== undefined && res.data.message !== null) {
              this.inputCurrentPassword.error(res.data.message);
            }
          })
          .finally(() => this.hideModal('modal-traitement-cours'));
    }
  }

  private watchCurrentPassword(val: ISigInputTextDto) {
    this.currentPassword = val;
  }

  private notValidedPasswordForm() {
    this.formPwdValid = this.passwordValidity && this.currentPassword.hasError === false;
    return !this.formPwdValid;
  }

}
