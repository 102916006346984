
import AbstractComponentVue from '../../services/AbstractComponentVue';
import {Component, Ref} from 'vue-property-decorator';
import Password from '@/components/personnal-info/PwdDef.vue';
import {IUserService} from '@/services/dto/IUserService';
import {services, store} from '@/main';
import {IPasswordRequest} from '@/services/dto/IDtos';
import AlertService from '@/utils/AlertService';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import ErrorUtils from '@/utils/ErrorUtils';

@Component({
	components: {
    SigInputTexte,
		Password,
	},
})
export default class VisioSoftService extends AbstractComponentVue {
  @Ref('visioPwd') private passwordGen?: Password;
  @Ref('refCorporateName') private inputCorporateName?: SigInputTexte;

  private get visioService(): IUserService {
    return store.getters.visioService;
  }

  private corporateName: string = this.visioService.physicalDeliveryOfficeNameAd ?
      this.visioService.physicalDeliveryOfficeNameAd : '';
  private visioSoftId: number = this.visioService.visioSoftId ? this.visioService.visioSoftId : 0;
  private passwordValidity: boolean = false;
  private corporateNameValidity: boolean = false;

  /**
   * Vide les champs MDP Visio.
   */
  public erasePassword(): void {
    if (this.passwordGen !== undefined) {
      this.passwordGen.erasePassword();
    }
  }

  private watchPasswordValid(valid: boolean) {
    this.passwordValidity = valid;
  }

  private wathCorporateName(val: ISigInputTextDto): void {
    this.corporateNameValidity = val.hasError;
    this.corporateName = val.value;
  }

  /**
   * Enregistre la modification du mot de passe visio.
   */
  private saveVisioPassword(): void {
    const passwordComponent: any = this.$refs.visioPwd;
    const passwordRequest: IPasswordRequest = {} as IPasswordRequest;
    passwordRequest.oldPassword = '';
    passwordRequest.newPassword = passwordComponent.newPassword;
    passwordRequest.userServiceType = 'VISIO_SOFT';
    // Le fenêtre pantientez.
    this.showModal('modal-traitement-cours');
    services.restService.callPatch('/api/v1/connected-user/password', passwordRequest)
        .then((res) => {
          this.hideModal('modal-traitement-cours');
          if (res.status === 200) {
            // On recharge les info fraîchement modifiées dans le localStorage
            services.loginService.ifCurrentUserReloadStore(store.getters.connectedUserAccountId,
                () => {
                  this.hideModal('modal-traitement-cours');
                  // Lance l'évènement passwordUpdated quand le mot de passe est mis à jour
                  this.$emit('passwordUpdated');
                  AlertService.success('La modification de votre mot de passe pour le Logiciel de visioconférence a bien été prise en compte.');
                });
            // On efface les input MDP.
            passwordComponent.erasePassword();
            this.watchPasswordValid(false);
          } else {
            this.hideModal('modal-traitement-cours');
          }
        })
        .catch(() => {
          this.hideModal('modal-traitement-cours');
        });
  }

  /**
   * Enregistre le changement de raison sociale.
   */
  private saveCorporateName(): void {
    // Le fenêtre pantientez.
    this.showModal('modal-traitement-cours');
    const uri: string = '/api/v1/user-services/visio-soft/' + this.visioSoftId + '/updatePhysicalDeliveryOfficeName';
    services.restService.callRawPost(uri, this.corporateName)
        .then((res) => {
          store.commit('saveTargetUrl', '/account/personal-info/VISIO_SOFT');
          services.restService.callGet('/api/v1/connected-user/current')
              .then(services.loginService.loadUserPostProcessing);
          // On recharge les info fraîchement modifiées dans le localStorage
          services.loginService.ifCurrentUserReloadStore(store.getters.connectedUserAccountId,
              () => {
                AlertService.success('La modification de la raison sociale pour le Logiciel de visioconférence ' +
                    'a bien été prise en compte.', 'OK');
              });
        })
        .catch((respError) => {
          ErrorUtils.showErrors(respError.data,
              'Une erreur s\'est produite lors de la mise à jour de la raison sociale : ');
        })
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }

  private mounted() {
    this.inputCorporateName?.setValue(this.corporateName);
  }
}
