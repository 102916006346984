
import {Component, Ref} from 'vue-property-decorator';
import {services, store} from '@/main';
import AlertService from '@/utils/AlertService';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import Password from '@/components/personnal-info/PwdDef.vue';
import {IPersonnalInfo} from '@/services/dto/IDtos';
import PatternUtils from '@/utils/PatternUtils';
import SigSelect from '@/components/user/SigSelect.vue';
import UserUtils from '@/utils/UserUtils';

@Component({
  components: {SigSelect, SigInputTexte, Password},
})
export default class Tab1UserIdentity extends AbstractComponentVue {
  private fullUser: IUserResponse = this.$store.getters.fullUser;
  private isEntity: boolean = this.$store.getters.isEntity;
  private isNotEntity: boolean = !this.isEntity;
  private isCollab: boolean = this.$store.getters.isCollab;
  private patternUtils = PatternUtils;

  private selectPosteOptions = UserUtils.allUserFonctionForSelectOptions;

  // *** Les champs à poster
  private telephone: ISigInputTextDto = {
    value: this.fullUser.mobilePhoneNumber ? this.fullUser.mobilePhoneNumber : '',
    hasError: false,
    msgError: '',
  };
  // mail
  private rescueEmail: ISigInputTextDto = {
    hasError: this.fullUser.rescueEmail === undefined || this.fullUser.rescueEmail === null
        || this.fullUser.rescueEmail.length === 0,
    value: this.fullUser.rescueEmail,
    msgError: '',
  };
  // post - pour les collab
  private poste: ISigInputTextDto = {
    hasError: this.fullUser.poste === undefined || this.fullUser.poste === null
        || this.fullUser.poste.length === 0,
    msgError: '',
    value: this.fullUser.poste ? this.fullUser.poste : '',
  };

  @Ref('refRescueMail')
  private inputRescueMail!: SigInputTexte;

  private saveAll() {
    // Le fenêtre pantientez.
    this.showModal('modal-traitement-cours');
    // Vérification back de la validité du mail de secours
    const rescueMailLower = this.rescueEmail.value.toLowerCase().trim();
    const promise: Promise<any> = services.restService
        .callGet('/api/v1/availability/rescue-email/' + rescueMailLower + '/');
    promise.then((res) => {
      this.hideModal('modal-traitement-cours');
      if (res.status === 200) {
        const personnalInfo: IPersonnalInfo = {} as IPersonnalInfo;
        if (this.poste.value !== '') {
          personnalInfo.poste = this.poste.value;
        }
        personnalInfo.rescueMail = rescueMailLower;
        personnalInfo.telephone = this.telephone.value;
        // On enregistre les modifications voulues par l'utilisateur
        services.restService.callPost('/api/v1/connected-user/current/personal-info', personnalInfo)
            .then((resu) => {
              services.loginService.ifCurrentUserReloadStore(store.getters.connectedUserAccountId,
                  () => {
                    this.hideModal('modal-traitement-cours');
                    if (this.isEntity) {
                      AlertService.success('La modification de vos informations personnelles a ' +
                          'bien été prise en compte. Pensez également à informer les collaborateurs ' +
                          'utilisant ce même compte Mon Espace Real.', 'OK');
                    } else {
                      AlertService.success('La modification de vos informations personnelles a bien' +
                          ' été prise en compte');
                    }
                  });
            })
            .catch(() => this.hideModal('modal-traitement-cours'));
      } else {
        this.hideModal('modal-traitement-cours');
      }
    }).catch((reason) => {
      if (reason.status === 409) {
        this.inputRescueMail.error('Cette adresse de secours est déjà utilisée par un autre utilisateur');
      }
      this.hideModal('modal-traitement-cours');
    });
  }


  /**
   * Vérification du format du téléphone portable.
   */
  private watchiPortable(val: ISigInputTextDto) {
    this.telephone = val;
  }

  /**
   * Vérification du format du téléphone portable.
   */
  private watchFonction(val: ISigInputTextDto) {
    this.poste = val;
  }

  /**
   * Validation de la saisie de l'adresse mail de secours.
   */
  private watchRescueEmail(val: ISigInputTextDto) {
      this.rescueEmail = val;
  }

  /**
   * Active/Désactive le bouton Valider.
   */
  private notValideForm(): boolean {
    const notValidChamps: boolean[] = [];
    notValidChamps.push(this.rescueEmail.hasError);
    // Rescue validation
    // Validation du téléphone portable
    notValidChamps.push(this.telephone.hasError);
    // Poste est vide alors que c'est un collab
    notValidChamps.push(this.poste.hasError && this.isCollab);
    return notValidChamps.some((x) => x);
  }

}
